<template>
	<el-dialog title="包含人员" custom-class="showParks" :visible.sync="showVisible" width="578px" center>
		<Table
			:table-data="tableData"
			:table-head="tableHead"
			:is-show-page="false"
			:is-show-selection="false"
			:is-show-index="false"
			:show-operation="false"
			:loading="loading"
			:tableHeight="0"
			:arraySpanMethod="arraySpanMethod"
		/>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			showVisible: false,
			tableData: [],
			tableHead: [
				{
					label: '驻派组名称',
					prop: 'groupName',
					formatter: (row) => {
						return row.groupName || '-';
					},
				},
				{
					label: '驻派组人员',
					prop: 'parkName',
					formatter: (row) => {
						return row.parkName || '-';
					},
				},
			],
		};
	},
	props: ['checkRow'],
	watch: {
		showVisible: {
			handler(val) {
				if (val) {
					this.setParksList(this.checkRow);
				} else {
					this.tableData = [];
				}
			},
			deep: true,
		},
	},
	methods: {
		// 处理拿到的公园数据
		setParksList(data) {
			console.log(data,'----');
			let dataList = [];
			dataList = data.medicalWorkers.map((item) => {
				return {
					groupName: data.name,
					parkName: item.name,
				};
			});
			this.tableData = [...dataList];
		},
		// 合并单元格
		arraySpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				if (rowIndex === 0) {
					return {
						rowspan: this.tableData.length,
						colspan: 1,
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0,
					};
				}
			}
		},
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .showParks {
	padding-bottom: 26px !important;
}
</style>